import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const labelStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "8px",
};

const RenewBulletinHistory = ({ open, onClose, itemDetails, onRenew }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2" align="center" fontWeight="bold">
          Renew Bulletin History
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Box sx={labelStyle}>
            <Typography variant="subtitle1" component="span" fontWeight="bold">
              Name:
            </Typography>
            <Typography variant="subtitle1" component="span">
              {itemDetails?.first_name + " " + itemDetails?.last_name}
            </Typography>
          </Box>
          <Box sx={labelStyle}>
            <Typography variant="subtitle1" component="span" fontWeight="bold">
              Business Name:
            </Typography>
            <Typography variant="subtitle1" component="span">
              {itemDetails?.business_name}
            </Typography>
          </Box>
          <Box sx={labelStyle}>
            <Typography variant="subtitle1" component="span" fontWeight="bold">
              Expiry Date:
            </Typography>
            <Typography variant="subtitle1" component="span">
              {itemDetails?.expiry_date}
            </Typography>
          </Box>
          <Box sx={labelStyle}>
            <Typography variant="subtitle1" component="span" fontWeight="bold">
              Plan Amount:
            </Typography>
            <Typography variant="subtitle1" component="span">
              {itemDetails?.ad_amount}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Button onClick={onClose} variant="contained" color="secondary" sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button onClick={onRenew} variant="contained" color="primary">
            Renew
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RenewBulletinHistory;
