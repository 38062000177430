import React, { useState, useEffect } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement, CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import axios from "axios"
import styled from 'styled-components';


export default function PaymentForm(props) {
	const [isLoading, setisLoading] = useState(false);
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	const [paymentState, paymentSetState] = React.useState({
		first_name: "",
		last_name: "",
		country: "",
		address: "",
		street: "",
		city: "",
		zipcode: "",
		country_id:""
	});
	useEffect(()=> {
		if(loggedInUserId!=null){
			axios.get(`/sanctum/csrf-cookie`).then(response => {
				axios.get(`/api/user/${loggedInUserId}`).then((response)=>{
					//console.log(response.data);
					var obj = JSON.parse(response.data.payment_details);
					// setUserContent(obj);
					//console.log(response.data.payment_details);
					//console.log(response.data.payment_details.first_name);
					paymentSetState({ ...paymentState, ['first_name']: obj.first_name || obj.FirstName, ['last_name']: obj.last_name || obj.LastName, ['country']: obj.country || obj.Country, ['address']: obj.address || obj.Address, ['street']: obj.street || obj.Street, ['city']: obj.city || obj.City, ['zipcode']: obj.zipcode || obj.ZipCode, ['country_id']: obj.country_id || obj.Country_Id});
				});
			});
		}
	}, [loggedInUserId]);
    const [success, setSuccess ] = useState(false)
    const [postData, setPostData ] = useState({
		paymentAmount: props.paymentAmount,
		getPlan: props.getPlan,
		getPackageType: props.getPackageType,
		loggedInUserId:  loggedInUserId
	})
    const stripe = useStripe()
    const elements = useElements()
	let [countryName,setCountryName] = useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/getcountry`).then((response)=>{
			setCountryName(response.data);
		});
		});
	}, []);
	const [paymentFormErrors, setPaymentFormErrors] = useState([]);
	let isPaymentSubmit = false;
	const handlePaymentChange = ({ target })=>{
		paymentSetState({ ...paymentState, [target.name]: target.value});
	};
	const [paymentMethodState, setPaymentMethodState] = useState({
		method: ""
	});
	const handlePaymentMethodChange = ({ target })=>{
		setPaymentMethodState({ ...paymentMethodState, [target.name]: target.value});
	};
	const [listPaymentMethod, setListPaymentMethod] = useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/list-payment-method/${loggedInUserId}`).then((response)=>{
			setListPaymentMethod(response.data);
			// console.log(response.data);
		});
		});
	}, []);
    const handleSubmit = async (e) => {
		e.preventDefault();
		paymentValidate(paymentState);
		if(isPaymentSubmit){
			setisLoading(true);
			let id = '';
			let error = '';
			if(paymentMethodState.method=='addmethod'){
				const cardDetailsElement = elements.getElement(CardNumberElement);
				const {error, paymentMethod} = await stripe.createPaymentMethod({
					type: "card",
					card: cardDetailsElement
				})
				/*if (error){
					const errorss = {};
					errorss.existsMethodCvcError = error.message;
					setPaymentFormErrors(errorss);
					// console.log(paymentFormErrors);
				}*/
				// const {id} = paymentMethod;
				id = paymentMethod.id;
			}else{
				id = paymentMethodState.method;
			}
			// const id = paymentMethodState.method
			const mData = new FormData();
			mData.append("user_id", postData.loggedInUserId);
			mData.append("plan_id", postData.getPlan);
			mData.append("plan_amount", postData.paymentAmount);
			mData.append("transaction_for_id", localStorage.getItem("added_item"));
			mData.append("package_type", postData.getPackageType);
			mData.append("plan_type", 1);
			mData.append("paymentAddress", JSON.stringify(paymentState));
			
			if(!error) {
				try {
					const response = await axios.post(`/api/payment`, {
						paymentState: paymentState,
						amount: props.paymentAmount,
						userId: loggedInUserId,
						id
					})
					
					if(paymentMethodState.method=='addmethod'){
						const { paymentIntent, error } = await stripe.confirmCardPayment(
							response.data.client_secret
						);
						if (error) return alert("Error in payment, please try again later");
						//const { paymentIntent } = await stripe.retrievePaymentIntent(response.data.client_secret);
						if(paymentIntent.status=='succeeded') {
							setPostData({ ...postData, ['transaction_id']: paymentIntent.id});
							mData.append("transaction_id", paymentIntent.id);
							//useEffect(()=> {
								axios.get(`/sanctum/csrf-cookie`).then(response => {
								axios.post(`/api/purchase-plan`, mData).then(response => {
									setSuccess(true);
								});
								});
							//}, []);
						}
					}else{
						const cardCvcElementDetails = elements.getElement(CardCvcElement);
						const { paymentIntent, error } = await stripe.confirmCardPayment(response.data.client_secret, {
							payment_method: id,
							payment_method_options: {
								card: {
									cvc: cardCvcElementDetails
								}
							},
						});
						// console.log(response.data.client_secret);
						// console.log(id);
						// console.log(error);
						// console.log(paymentIntent);
						// console.log(error.message);
						// console.log(paymentIntent);
						// console.log(paymentIntent.status);
						/*if (error){
							const errorss = {};
							errorss.existsMethodCvcError = error.message;
							setPaymentFormErrors(errorss);
							// console.log(paymentFormErrors);
						}*/
						//const { paymentIntent } = await stripe.retrievePaymentIntent(response.data.client_secret);
						if(error.payment_intent.status=='succeeded'){
							setPostData({ ...postData, ['transaction_id']: error.payment_intent.id});
							mData.append("transaction_id", error.payment_intent.id);
							//useEffect(()=> {
							axios.get(`/sanctum/csrf-cookie`).then(response => {
								axios.post(`/api/purchase-plan`, mData).then(response => {
									setSuccess(true);
								});
							});
						}else{
							if(paymentIntent.status=='succeeded') {
								setPostData({ ...postData, ['transaction_id']: paymentIntent.id});
								mData.append("transaction_id", paymentIntent.id);
								//useEffect(()=> {
									axios.get(`/sanctum/csrf-cookie`).then(response => {
									axios.post(`/api/purchase-plan`, mData).then(response => {
										setSuccess(true);
									});
									});
								//}, []);
							}else{
								if (error){
									const errorss = {};
									errorss.existsMethodCvcError = error.message;
									setPaymentFormErrors(errorss);
									console.log(paymentFormErrors);
								}
							}							
						}
					}
				} catch (error) {
					console.log("Error", error);
				}
			} else {
				console.log(error.message)
			}
			setisLoading(false);
		}
	}
	const paymentValidate = (values) => {
		const errors = {};
		if (!values.first_name) {
			isPaymentSubmit = false;
			errors.first_name = "First Name field is required!";
		}else{
			isPaymentSubmit = true;
		}
		if (!values.last_name) {
			isPaymentSubmit = false;
			errors.last_name = "Last Name field is required!";
		}else{
			isPaymentSubmit = true;
		}
		if (!values.country_id) {
			isPaymentSubmit = false;
			errors.country_id = "Country field is required!";
		}else{
			isPaymentSubmit = true;
		}
		if (!values.address) {
			isPaymentSubmit = false;
			errors.address = "Address field is required!";
		}else{
			isPaymentSubmit = true;
		}
		if (!values.street) {
			isPaymentSubmit = false;
			errors.street = "Street field is required!";
		}else{
			isPaymentSubmit = true;
		}
		if (!values.city) {
			isPaymentSubmit = false;
			errors.city = "City field is required!";
		}else{
			isPaymentSubmit = true;
		}
		if (!values.zipcode) {
			isPaymentSubmit = false;
			errors.zipcode = "Zipcode field is required!";
		}else{
			isPaymentSubmit = true;
		}
		setPaymentFormErrors(errors);
		return isPaymentSubmit;
	}
const inputStyle = {
      iconColor: '#c4f0ff',
      color: '#000',
      fontWeight: '400',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '15px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#fce883' },
	  "::placeholder": { color: "#d7d0d5" }
}
const expiryinputStyle = {
      iconColor: '#c4f0ff',
      color: '#000',
      fontWeight: '400',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '15px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#fce883' },
	  "::placeholder": { color: "#d7d0d5" }
}
/*const CardInputWrapper = styled.div`
	// border: 2px solid #818189;
	border: 1px solid #C4C4C4;
	height: 37px;
	padding: 0.375rem 0.75rem;
    background-color: #fff;
	border-radius: 0.25rem;
    line-height: 1.5;
    margin-bottom: 10px;
`;*/
    return (
        <>
        {!success ? 
        <form onSubmit={handleSubmit}>
			<h4>Billing address</h4>
			<div className="row">
				<div className="col-md-6">
					<div className="form-group">
						<input type="text" className="form-control" name="first_name" value={paymentState.first_name} onChange={handlePaymentChange} placeholder="First name" />
						<div className="errorMesg">{paymentFormErrors.first_name}</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className="form-group">
					   <input type="text" className="form-control" name="last_name" value={paymentState.last_name} onChange={handlePaymentChange} placeholder="Last name" />
					   <div className="errorMesg">{paymentFormErrors.last_name}</div>
					</div>
				</div>
				<div className="col-md-12">
					<div className="form-group">
					   <input type="text" className="form-control" name="address" value={paymentState.address} onChange={handlePaymentChange} placeholder="Address" />
					   <div className="errorMesg">{paymentFormErrors.address}</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className="form-group">
						<select className="form-control" id="country_id" name="country_id" value={paymentState.country_id} onChange={handlePaymentChange}>
							<option value=''>-select_country-</option>
							{countryName.map((val) => {
									return <>	
									<option value={val?.id}>{val.country_name}</option>
								</>;
							})}
						</select>
						<div className="errorMesg">{paymentFormErrors.country_id}</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className="form-group">
					   <input type="text" className="form-control" name="street" value={paymentState.street} onChange={handlePaymentChange} placeholder="Street" />
					   <div className="errorMesg">{paymentFormErrors.street}</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className="form-group">
					   <input type="text" className="form-control" name="city" value={paymentState.city} onChange={handlePaymentChange} placeholder="City" />
					   <div className="errorMesg">{paymentFormErrors.city}</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className="form-group">
					   <input type="text" className="form-control" name="zipcode" value={paymentState.zipcode} onChange={handlePaymentChange} placeholder="Zipcode" />
					   <div className="errorMesg">{paymentFormErrors.zipcode}</div>
					</div>
				</div>
			</div>
			<h4>Card details</h4>
			{listPaymentMethod.map((val) => {
				return <div key={val.card.id}>
					<div className="d-flex">
						<input type="radio" className="savedCardRadio" value={val.id} name="method" onChange={handlePaymentMethodChange}/>
						<div>{val.card.brand} {val.card.funding} card <strong>xxxx {val.card.last4}</strong></div>
					</div>
					<div className="m-4 mt-0">Expires {val.card.exp_month}/{val.card.exp_year}
						{paymentMethodState.method==val.id ? 
							<>
							<div className="cardElement saveCardCvv">
								<CardCvcElement
									options={{
										showIcon: true,
										style: {
											base: inputStyle,
											invalid: {
												iconColor: '#FFC7EE',
												color: '#FFC7EE',
											},
										},
									}}
								/>
							</div>
							<div className="errorMesg">{paymentFormErrors.existsMethodCvcError}</div>
							</>
							:
							null
						}
					</div>
				</div>;
			})}
			<div className="d-flex">
				<input type="radio" className="savedCardRadio" value="addmethod" name="method" onChange={handlePaymentMethodChange}/>
				<div><strong>Add new</strong></div>
			</div>
			{paymentMethodState.method=='addmethod' ? 
            <fieldset className="FormGroup">
                <div className="FormRow row m-0">
					<div className="cardElement col-md-12">
						<CardNumberElement
							options={{
								showIcon: true,
								style: {
									base: inputStyle,
									invalid: {
										iconColor: '#d7d0d5',
										color: '#d7d0d5',
									},
								},
							}}
						/>
					</div>
					<div className="cardElement mr-2 cardExpiryElementClass">
						<CardExpiryElement
							options={{
								style: {
									base: expiryinputStyle
								},
							}}
						/>
					</div>
					<div className="cardElement cardCvcElementClass">
						<CardCvcElement
							options={{
								showIcon: true,
								style: {
									base: inputStyle,
									invalid: {
										iconColor: '#FFC7EE',
										color: '#FFC7EE',
									},
								},
							}}
						/>
					</div>
				  {/*<CardElement 
						options={{
							iconStyle: 'solid',
							style: {
							  base: inputStyle,
							  invalid: {
								iconColor: '#FFC7EE',
								color: '#FFC7EE',
							  },
							},
						  }}
				  />*/}
                </div>
            </fieldset>
			:
			null
			}
			<button type="submit" className="btn btn-primary mt-2" disabled={isLoading}>
			{isLoading ? <div className="btnloader"></div> : "Pay"}
			</button>
        </form>
        :
		<div>
           <h2>You have successfully purchase your plan</h2>
		</div> 
        }
            
        </>
    )
}