import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import {
  CircularProgress,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import axios from "axios";
import RenewBulletinHistory from "./RenewBulletinHistory"; // Import the modal

const header = [
  { key: "Name" },
  { key: "Business Name" },
  { key: "Email" },
  { key: "Contact Number" },
  { key: "Expiry Date" },
  { key: "Plan Status" },
  { key: "Amount" },
  { key: "Options" },
];

export default function BulletinHistory() {
  let loggedInUserId = localStorage.getItem("loggedin_user_id");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios.get(`/sanctum/csrf-cookie`).then((response) => {
      axios
        .get(`/api/user-bulletin-history/${loggedInUserId}`)
        .then((response) => {
          setData(response.data?.data);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, []);

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedItem(null);
  };

  const handleRenew = () => {
    // Perform renew action here
    console.log("Renewing item:", selectedItem);
    handleCloseModal();
  };

  return (
    <TableContainer component={Paper}>
      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", padding: "20px" }}
        >
          <CircularProgress />
        </div>
      ) : data && data.length > 0 ? (
        <Table sx={{ minWidth: 670 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {header?.map((item, i) => (
                <TableCell key={i}>{item?.key}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.first_name + " " + row?.last_name}
                </TableCell>
                <TableCell>{row.business_name}</TableCell>
                <TableCell>{row.email_id}</TableCell>
                <TableCell>{row.contact_number}</TableCell>
                <TableCell>{row.expiry_date}</TableCell>
                <TableCell>{row.plan_status}</TableCell>
                <TableCell>{row.ad_amount}</TableCell>
                <TableCell>
                  {row?.expiry_date &&
                  new Date(row?.expiry_date) < new Date() ? (
                    <Tooltip title="Renew">
                      <IconButton>
                        <AutorenewIcon
                          color="primary"
                          onClick={() => handleOpenModal(row)}
                          style={{ cursor: "pointer" }}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography variant="h6" align="center" style={{ padding: "20px" }}>
          No Data Found
        </Typography>
      )}
      {/* Modal for renewing bulletin history */}
      {selectedItem && (
        <RenewBulletinHistory
          open={openModal}
          onClose={handleCloseModal}
          itemDetails={selectedItem}
          onRenew={handleRenew}
        />
      )}
    </TableContainer>
  );
}
