import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CircularProgress, Typography, Tooltip, IconButton } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import axios from "axios";
import RenewItemOrder from "./RenewItemOrder"; // Import the RenewModal component

const header = [
  { key: "Item Name" },
  { key: "Category" },
  { key: "Price" },
  { key: "Status" },
  { key: "Is Sponsored" },
  { key: "Plan Expiry Date" },
  { key: "Plan Status" },
  { key: "Plan Amount" },
  { key: "Options" },
];

export default function ItemsOrderHistory() {
  let loggedInUserId = localStorage.getItem("loggedin_user_id");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/sanctum/csrf-cookie`)
      .then((response) => {
        axios
          .get(`/api/user-item-history/${loggedInUserId}`)
          .then((response) => {
            setData(response.data?.data);
          })
          .finally(() => {
            setLoading(false);
          });
      });
  }, []);

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };

  const handleRenew = () => {
    // Implement the renew functionality here
    console.log("Renew item", selectedItem);
    handleCloseModal(); // Close the modal after renewing
  };

  return (
    <TableContainer component={Paper}>
      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", padding: "20px" }}
        >
          <CircularProgress />
        </div>
      ) : data && data.length > 0 ? (
        <Table sx={{ minWidth: 670 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {header?.map((item, i) => (
                <TableCell key={i}>{item?.key}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.categoryname}</TableCell>
                <TableCell align="right">{row.price}</TableCell>
                <TableCell align="right">
                  {row.status === "1" ? "Active" : "Inactive"}
                </TableCell>
                <TableCell align="right">
                  {row.is_sponsered === "1" ? "Sponsored" : null}
                </TableCell>
                <TableCell align="right">{row.expiry_date}</TableCell>
                <TableCell align="right">
                  {row.plan_status === "1"
                    ? "Active"
                    : row.plan_status === "2"
                    ? "Expired"
                    : "Cancelled"}
                </TableCell>
                <TableCell align="right">{row.plan_amount}</TableCell>
                <TableCell align="right">
                  {row?.expiry_date &&
                  new Date(row?.expiry_date) < new Date() ? (
                    <Tooltip title="Renew">
                      <IconButton>
                      <AutorenewIcon
                        color="primary"
                        onClick={() => handleOpenModal(row)}
                      />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography
          variant="h6"
          align="center"
          style={{ padding: "20px" }}
        >
          No Data Found
        </Typography>
      )}

      {/* Renew Modal */}
      {selectedItem && (
        <RenewItemOrder
          open={modalOpen}
          onClose={handleCloseModal}
          itemDetails={selectedItem}
          onRenew={handleRenew}
        />
      )}
    </TableContainer>
  );
}
