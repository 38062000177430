import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, NavLink, Button, useNavigate } from 'react-router-dom';
import { QrCodeOutline, PersonOutline, ListOutline, AppsOutline, ChatbubblesOutline, EllipsisHorizontalOutline, NotificationsOutline, HelpCircleOutline, ChatboxEllipsesOutline, TicketOutline, ChatboxOutline, HeartOutline, StarOutline, Star, PencilSharp, LogOutOutline, TimeOutline, TrashOutline, BagHandleOutline, CheckmarkSharp, MenuOutline, CloseCircleOutline } from 'react-ionicons';

import StripeContainer from '../stripe/StripeContainer';


import Userleftmenu from './Userleftmenu';
import axios from "axios";

function Sponsoredplans(props, {page}) {	
	const navigate = useNavigate()
	let loggedInUserId = localStorage.getItem("loggedin_user_id");
	let item_id = localStorage.getItem("added_item");
	//console.log("items -"+item_id);
	if(loggedInUserId==null)
	{
	   navigate(`/`)
	}
	
	const [publicKey, setPublicKey] = React.useState([]);
	useEffect(()=> {
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/stripe-public-key`).then((response)=>{
			// console.log(response.data);
			setPublicKey(response.data);
		});
		});
	}, []);
	
	let languageContent = props?.props?.languageContent;
	// console.log("language-Content:"+languageContent);
	
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);		
	}
	const [show, setShow] = React.useState();
	const [sponsorPlan, setSponsorPlan] = React.useState([]);
	useEffect(()=> {
		props?.page=='edit' ? 
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/sponsore-plan/${loggedInUserId}/${item_id}/${props?.props?.language}`).then((response)=>{		
			console.log(response.data,'sponsorPlan');
			setSponsorPlan(response?.data);
		});
		})
		:
		axios.get(`/sanctum/csrf-cookie`).then(response => {
			axios.get(`/api/sponsore-package`).then((response)=>{		
				console.log(response.data,'sponsorPlan');
				setSponsorPlan(response?.data);
			});
			});
	}, []);
	//console.log(props.language);
	const makePayment = token => {
		// console.log('makePayment');
	}
	
	{/*let [languageContent, setLanguageContent] = useState([]);
	useEffect(()=> {
		//alert(language);
		axios.get(`/sanctum/csrf-cookie`).then(response => {
		axios.get(`/api/language/${props.language}`).then((response)=>{
			setLanguageContent(response.data);
			//console.log(response.data);
		});
		});
}, [props.language]);*/}
//Language change end
	const [showPayment, setShowPayment] = useState(false);
	const [paymentAmount, setPaymentAmount] = useState('');
	const [getPlan, setGetPlan] = useState('');
	const [getPackageType, setGetPackageType] = useState('');
	const buyPlan = (price,id,package_type) => () => {
		
		//console.log(price);
		//console.log(id);

		setPaymentAmount(price);
		setGetPlan(id);
		setGetPackageType(package_type);
		setShowPayment(true);
	};
	
	/*useEffect(()=> {
		document.body.classList.remove('disable_load_content');
	}, []);*/
	return <>
		<section>
			<div className="products-section-innerpage">
				<div className="container myLeftContainer">
					<div className="row">
						{/* <Userleftmenu state={{userContent: props?.props?.userContent }} userContent={props?.props?.userContent} languageContent={languageContent} isOpen={isOpen}/> */}
						<div className="col-lg-10 col-md-10 col-sm-9 col-12 categoriesRight pageRelated">
							{/* <div className="page-title myPageTitle">
								<div className="mobCat"><MenuOutline onClick={onClickMenu}/></div>
								<div className="container">
									<div className="row">
										<h1>Sponsored Plans</h1>
										<div className="breadcrumb"><Link to="/">{languageContent?.home} </Link>/ Sponsored Plans</div>
									</div>
								</div>
							</div> */}
							<div className="nInfo">
								<div className="row sPlans">
								{showPayment ? (
									<StripeContainer publicKey={publicKey} paymentAmount={paymentAmount} getPlan={getPlan} showPayment={showPayment} onPaymentModalClose={showPayment => setShowPayment(false)} getPackageType={getPackageType}/>
								) : ''}
								{sponsorPlan.map((val,k) => {
									let colourClass = val.package_name == 'Silver' ? 'sSliver' : val.package_name == 'Gold' ? 'sGold' : 'sPlatinum';
									return <>	
									<div className="col-md-4" key={val.id}>
										<div className={`sPlansPart  ${colourClass}`}>
											<h6><span>{val.package_name}</span></h6>
											<div className="sTime"><sup>$</sup><span>{val.price}<small>{val.package_type == '1' ? '3 days' : val.package_type == '2' ? '7_days' : '1 Month'}</small></span></div>
											{val.plan_status == 1 ? (
											  <div className="buyNowBtn"><button className="btn"><BagHandleOutline />{languageContent.purchased}</button></div>
											  
											) : (
											  <div className="buyNowBtn"><button className="btn" onClick={buyPlan(`${val.price}`,`${val.id}`,`${val.package_type}`)}><BagHandleOutline />{languageContent.buy_now}</button></div>
											)}
											{val.plan_status == 1 && (
											<p><CheckmarkSharp />Will expire on {val.validity}</p> ) 
											}
											{/* <div
											  dangerouslySetInnerHTML={{__html: val.package_details}}
											/> */}
										</div>
									</div>
									</>;
								    })}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Sponsoredplans;